import React from "react";
import SECard from "../../components/SECard";
import * as knowledgeData from "./knowledge.json";

const Knowledge = () => {
  let learning = [...knowledgeData.learning];
  let tips = [...knowledgeData.tips];
  let help = [...knowledgeData.help];

  return (
    <>
      <div className={`bx--grid`}>
        <h3>Learning</h3>
        <div className="bx--row">
          {learning.map((card, index) => {
            return (
              <SECard
                key={index}
                heading={card.name}
                subheading={card.subline}
                category={card.category}
                copytext={card.description}
                primaryactionlabel={card.actions[0].label}
                primaryactionlink={card.actions[0].link}
                color={card.color}
                logo={card.logo}
                price={card.price}
                label={card.label}
              />
            );
          })}
        </div>
        <h3>Tips & Tricks</h3>
        <div className="bx--row">
          {tips.map((card, index) => {
            return (
              <SECard
                key={index}
                heading={card.name}
                subheading={card.subline}
                category={card.category}
                copytext={card.description}
                primaryactionlabel={card.actions[0].label}
                primaryactionlink={card.actions[0].link}
                color={card.color}
                logo={card.logo}
                price={card.price}
                label={card.label}
              />
            );
          })}
        </div>
        <h3>Help & Support</h3>
        <div className="bx--row">
          {help.map((card, index) => {
            return (
              <SECard
                key={index}
                heading={card.name}
                subheading={card.subline}
                category={card.category}
                copytext={card.description}
                primaryactionlabel={card.actions[0].label}
                primaryactionlink={card.actions[0].link}
                color={card.color}
                logo={card.logo}
                price={card.price}
                label={card.label}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Knowledge;
