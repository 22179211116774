import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Content } from "carbon-components-react/es/components/UIShell";
import SEHeader from "./components/SEHeader";
import SEFooter from "./components/SEFooter";
import Extensions from "./contents/Extensions";
import Knowledge from "./contents/Knowledge";
import "./app.scss";

function App() {
  return (
    <div className="App">
      <SEHeader />
      <Content>
        <Switch>
          <Redirect exact from="/" to="/extensions" />
          <Route path="/extensions" component={Extensions} />
          <Route path="/knowledge" component={Knowledge} />
        </Switch>
      </Content>
      <SEFooter />
    </div>
  );
}

export default App;
