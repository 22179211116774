import React from "react";
import { Button, Column } from "carbon-components-react";
import { withRouter } from "react-router-dom";

const SECard = props => {
  const buttonClickedHandler = link => {
    window.location.href = link;
  };

  const background = (c = props.color) => {
    if (c[0] !== "#") {
      return {
        background:
          "linear-gradient(to " +
          c["direction"] +
          "," +
          c["start"] +
          "," +
          c["end"] +
          ")"
      };
    }
    return { backgroundColor: c };
  };

  return (
    <Column className="bx--col-sm-4 bx--col-md-4 bx--col-lg-8 bx--col-xlg-4 bx--col-max-4 se-card">
      <div className="se-card__inner">
        {props.logo !== "" ? (
          <div className="se-card__image" style={background()}>
            {props.category !== "" ? (
              <div className="se-card__category">{props.category}</div>
            ) : null}
            {props.label !== "" ? (
              <div className="se-card__label">{props.label}</div>
            ) : null}
            <img src={props.logo} alt={"Logo von " + props.heading} />
          </div>
        ) : null}
        <div className="se-card__content">
          <h4>
            <span className="se-card__heading">{props.heading}</span>
            {props.subheading !== "" ? (
              <span className="se-card__subheading">
                {props.subheading} | {props.price}
              </span>
            ) : null}
          </h4>
          <p dangerouslySetInnerHTML={{ __html: props.copytext }} />
        </div>
        <div className="se-card__actions">
          <a href={props.primaryactionlink} className="button">
            <Button
              kind="se"
              onClick={() => buttonClickedHandler(props.primaryactionlink)}
            >
              {props.primaryactionlabel}
            </Button>
          </a>
          {props.secondaryactionlabel != null ? (
            <a href={props.secondaryactionlink} className="button">
              <Button
                kind="set"
                onClick={() => buttonClickedHandler(props.secondaryactionlink)}
              >
                {props.secondaryactionlabel}
              </Button>
            </a>
          ) : null}
        </div>
      </div>
    </Column>
  );
};

export default withRouter(SECard);
