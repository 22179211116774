import React from "react";
import { Button, Grid, Row, Column } from "carbon-components-react";

const SEHero = props => {
  const buttonClickedHandler = link => {
    window.location.href = link;
  };

  const background = (c = props.color) => {
    if (c[0] !== "#") {
      return {
        background:
          "linear-gradient(to " +
          c["direction"] +
          "," +
          c["start"] +
          "," +
          c["end"] +
          ")"
      };
    }
    return { backgroundColor: c };
  };

  return (
    <Grid className="se-hero">
      <Row>
        <Column
          className="se-hero__image bx--col-sm-4 bx--col-lg-7 bx--offset-lg-1 bx--col-xlg-7 bx--col-max-7"
          style={background()}
        >
          <img src={props.logo} alt={"Logo von " + props.heading} />
        </Column>
        <Column className="se-hero__content bx--col-sm-4 bx--col-lg-6 bx--offset-lg-1 bx--col-xlg-6 bx--col-max-6">
          <h2>
            <span className="se-hero__topline">{props.topline}</span>
            <span className="se-hero__heading">{props.heading}</span>
            <span className="se-hero__subheading">
              {props.subheading} | {props.price}
            </span>
          </h2>
          <p dangerouslySetInnerHTML={{ __html: props.copytext }} />
          <div className="se-hero__actions">
            <a href={props.primaryactionlink} className="button">
              <Button
                kind="se"
                onClick={() => buttonClickedHandler(props.primaryactionlink)}
              >
                {props.primaryactionlabel}
              </Button>
            </a>
            <a href={props.secondaryactionlink} className="button">
              <Button
                kind="set"
                onClick={() => buttonClickedHandler(props.secondaryactionlink)}
              >
                {props.secondaryactionlabel}
              </Button>
            </a>
          </div>
        </Column>
      </Row>
    </Grid>
  );
};

export default SEHero;
