import React from "react";
import SEHero from "../../components/SEHero";
import SECard from "../../components/SECard";
import * as extensionsData from "./extensions.json";

const Extensions = () => {
  let featured = extensionsData.featured[0];
  let list = [...extensionsData.list];

  return (
    <>
      <SEHero
        topline="Extension of the Month"
        heading={featured.name}
        subheading={featured.subline}
        category={featured.category}
        copytext={featured.description}
        primaryactionlabel={featured.actions[0].label}
        primaryactionlink={featured.actions[0].link}
        secondaryactionlabel={featured.actions[1].label}
        secondaryactionlink={featured.actions[1].link}
        color={featured.color}
        logo={featured.logo}
        price={featured.price}
        label={featured.label}
      />
      <div className={`bx--grid`}>
        <div className="bx--row">
          {list.map((plugin, index) => {
            return (
              <SECard
                key={index}
                heading={plugin.name}
                subheading={plugin.subline}
                category={plugin.category}
                copytext={plugin.description}
                primaryactionlabel={plugin.actions[0].label}
                primaryactionlink={plugin.actions[0].link}
                secondaryactionlabel={plugin.actions[1].label}
                secondaryactionlink={plugin.actions[1].link}
                color={plugin.color}
                logo={plugin.logo}
                price={plugin.price}
                label={plugin.label}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Extensions;
