import React from "react";
import { Row, Column } from "carbon-components-react";

const SEFooter = (props) => (
  <footer className="bx--grid">
    <Row>
      <Column className="bx--col-sm-4 bx--col-md-8 bx--col-lg-8">
        v2020.10, last updated: 2020-10-10
      </Column>
      <Column className="bx--col-sm-4 bx--col-md-8 bx--col-lg-8 right">
        curated by{" "}
        <a href="https://laura-adriana.design/" target="_blank">
          Laura-Adriana Florean
        </a>{" "}
        &{" "}
        <a href="https://alexander.johmann.eu/" target="_blank">
          Alexander Johmann
        </a>
      </Column>
    </Row>
  </footer>
);

export default SEFooter;
