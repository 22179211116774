import React from "react";
import { NavLink, Link } from "react-router-dom";
import {
  Header,
  HeaderName,
  HeaderNavigation,
  HeaderMenuItem,
  SkipToContent
} from "carbon-components-react";
import logo from "../../assets/logo.svg";

const SEHeader = () => (
  <Header aria-label="Sketch Essentials" className="se-header bx--grid">
    <img src={logo} alt="Logo" className="se-header__logo" />
    <SkipToContent />
    <HeaderName element={Link} to="/" prefix="">
      Sketch Essentials
    </HeaderName>
    <HeaderNavigation aria-label="Sketch Essentials">
      <HeaderMenuItem element={NavLink} to="/extensions">
        Extensions
      </HeaderMenuItem>
      <HeaderMenuItem element={NavLink} to="/knowledge">
        Knowledge
      </HeaderMenuItem>
    </HeaderNavigation>
  </Header>
);

export default SEHeader;
